"use client";

import { FC, ReactNode } from "react";
import MUI_MODAL from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { FaPlus } from "react-icons/fa";

import { useStyles } from "./styles";

interface ModalProps {
  children: ReactNode;
  open: boolean;
  heading?: string;
  icon?: ReactNode;
  subheading?: string;
  isError?: boolean;
  onClose: () => void;
  className?: string;
  disableBackdropClose?: boolean;
  footer?: ReactNode;
  positionOverrides?: string;
  showCloseIcon?: boolean;
}

const Modal: FC<ModalProps> = ({
  children,
  open,
  heading,
  icon,
  subheading,
  isError = false,
  onClose,
  className = "",
  disableBackdropClose = false,
  footer,
  positionOverrides = "",
  showCloseIcon = true,
}) => {
  const { classes, cx } = useStyles();

  const handleClose = (
    event: Record<string, never>,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick" && !disableBackdropClose) {
      onClose();
    }
  };

  return (
    <MUI_MODAL
      open={open}
      onClose={(event, reason) => handleClose(event, reason)}
      className={classes.modal}
    >
      <div className={positionOverrides || classes.container} onClick={onClose}>
        <Card
          className={cx(classes.card, className)}
          onClick={(e) => e.stopPropagation()}
        >
          {heading && (
            <div className={classes.heading}>
              <Typography variant="h4" color={isError ? "error" : "primary"}>
                {heading}
              </Typography>
              <div className={classes.headingIcon}>{icon}</div>
            </div>
          )}
          {subheading && (
            <div className={classes.subheading}>
              <Typography variant="body1" className={classes.subheadingText}>
                {subheading}
              </Typography>
            </div>
          )}
          <div className={classes.content}>{children}</div>
          {showCloseIcon && (
            <FaPlus
              className={classes.closeIcon}
              onClick={onClose}
              aria-label="Close Modal"
            />
          )}
          {footer ?? null}
        </Card>
      </div>
    </MUI_MODAL>
  );
};

export default Modal;
